import React, { useState, useEffect } from "react";
import Button from "./Button";
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import InternalLink from "../components/InternalLink";

const CookiesPopup = () => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);

  const acceptCookies = () => {
    setShowPopup(false);
    loadMetaPixel();
    localStorage.setItem("cookiesAccepted", "true");
  };
  
  const close = () => {
    setShowPopup(false);
  }

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted === "true") {
      loadMetaPixel();
    } else {
      setShowPopup(true);
    }
  }, []);

  function loadMetaPixel() {
    if (!window.fbq) {
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
      
      window.fbq("init", "1231351871643249");
      window.fbq('dataProcessingOptions', ['LDU'], 0, 0);
      window.fbq("consent", "grant");
      window.fbq("track", "PageView");
    }    
  }

  return (
    <div className="z-100">
      {showPopup && (
        <div className="rounded flex flex-col shadow-lg fixed bottom-4 mx-4 md:mx-0 md:right-4 bg-white text-babymamGray-700 py-4 px-10 md:w-[500px]">
          <button
            className="self-end text-xl text-babymamGray-400"
            onClick={acceptCookies}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                fill="#9E9E9E"
              />
            </svg>
          </button>
          <h1 className="text-babymamGray-800 font-semibold text-[18px] md:text-[22px]">
            {t("cookiespopup.title")}
          </h1>
          <span className="m-2 mb-5 text-[14px] md:text-[16px]">
          {t("cookiespopup.text")} {" "}
            <InternalLink to="/cookies" className="text-babymam-600">
            {t("cookiespopup.policy_link")}
            </InternalLink>
          </span>
          <div>
            <Button desk="16" mobile="16" onClick={acceptCookies}>
            {t("cookiespopup.accept")}
            </Button>
          </div>
          <div className="mt-2">
            <InternalLink to="/cookies">
              <Button
                desk="16"
                mobile="16"
                onClick={acceptCookies}
                theme="white"
              >
                {t("cookiespopup.necessary")}
              </Button>
            </InternalLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookiesPopup;
